import React, { useCallback } from 'react';
import { UserItem } from '~/common';
import {
  Crud,
  CrudEditBaseView,
  CrudEditViewConfig,
  CrudIndexDataGridView,
  CrudIndexViewConfig,
} from '~/common/crud';
import { useUsersCrudDataProvider } from '~/di';
import { UsersInfoCrud } from './users-info-crud';
import { UsersToolbar } from './users-toolbar';

export const UsersCrud: React.VFC = () => {
  const usersCrudDataProvider = useUsersCrudDataProvider();

  const indexView = useCallback((config: CrudIndexViewConfig) => (
    <CrudIndexDataGridView
      config={config}
      toolbar={UsersToolbar}
      columns={[
        {
          field: 'firstname',
          headerName: 'Name',
          flex: 1,
        },
        {
          field: 'id',
          headerName: 'Auth',
          flex: 1,
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
        },
      ]}
    />
  ), []);

  const editView = useCallback((config: CrudEditViewConfig<UserItem>) => (
    <CrudEditBaseView
      config={config}
      form={UsersInfoCrud}
    />
  ), []);

  return (
    <Crud
      crudDataProvider={usersCrudDataProvider}
      indexView={indexView}
      editView={editView}
      title={'Users'}
      endpoint={'users'}
    />
  );
};
