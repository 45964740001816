/* eslint-disable no-param-reassign */
export function addPrefix(value: string, prefix?: string): string {
  return prefix ? `${prefix}[${value.split(']').join('').split('[').join('][')}]` : value;
}

export function makeQueryString(query: Record<string, any>, prefix?: string): string {
  return Object.keys(query).map((key) => {
    if (Array.isArray(query[key])) {
      return query[key].map(
        (row: string | number | boolean) => [`${addPrefix(key, prefix)}[]`, encodeURIComponent(String(row))].join('='),
      ).join('&');
    }

    if (typeof query[key] === 'object') {
      return makeQueryString(query[key], addPrefix(key, prefix));
    }

    return [addPrefix(key, prefix), encodeURIComponent(String(query[key]))].join('=');
  }).join('&');
}

export function getQueryFromString(str: string): any {
  const urlSearchParams = new URLSearchParams(str);
  return Object.fromEntries(urlSearchParams.entries());
}

export function objRemoveEmptyField(obj: any) {
  Object.keys(obj).forEach((key) => !obj[key] && obj[key] !== 0 && delete obj[key]);
}

export function makeEndpoint(
  value: string,
  params?: Record<string, string | number | boolean> | null,
  query?: object,
): string {
  const url = params
    ? Object
      .keys(params)
      .reduce((data, key) => data.split(`:${key}`).join(String(params[key])), value)
    : value;

  return query ? [url, makeQueryString(query)].join('?') : url;
}

export function makeApiEndpoint(
  value: string,
  params?: Record<string, string | number | boolean> | null,
  query?: object,
): string {
  return makeEndpoint(`/api/${value}`, params, query);
}
