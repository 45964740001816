import { BehaviorSubject } from 'rxjs';
import { getQueryFromString, makeQueryString, objRemoveEmptyField } from '~/utils';

export class UrlParamsService {
  urlString$ = new BehaviorSubject<string>(window.location.search);

  setUrlParams(params: any) {
    objRemoveEmptyField(params);
    const queryString = makeQueryString(params);

    const newUrl = queryString.length ? `?${queryString}` : '';

    window.history.replaceState({}, document.title, newUrl);
    this.urlString$.next(newUrl);
  }

  readUrlParams(): { limit: number; offset: number; search?: string; } {
    return getQueryFromString(this.urlString$.value);
  }

  clearUrl() {
    this.setUrlParams({ });
  }
}
