import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import React from 'react';

export type BaseLayoutMenuButtonProps = {
  isShown: boolean;
  onClickMenu: () => void;
  onClickArrow: () => void;
};

export const BaseLayoutMenuButton: React.VFC<BaseLayoutMenuButtonProps> = (props) => (
  <>
    <IconButton
      size={'large'}
      edge={'start'}
      color={'inherit'}
      aria-label={'open drawer'}
      sx={{ display: { sm: 'block', md: 'none ' } }}
      onClick={props.onClickMenu}
    >
      <MenuIcon/>
    </IconButton>
    <IconButton
      size={'large'}
      edge={'start'}
      color={'inherit'}
      aria-label={'open drawer'}
      sx={{ display: 'none' }}
      onClick={props.onClickArrow}
    >
      {props.isShown
        ? <ArrowBackIos/>
        : <ArrowForwardIos/>}
    </IconButton>
  </>
);
