import React, { useState } from 'react';
import { BaseLayout } from '~/common/layouts';
import { LayoutIcons } from './layout-icons';
import { LayoutSidebar } from './layout-sidebar';

export const Layout: React.FC = (props) => {
  const [notifications] = useState<Array<string>>(['Notification 1', 'Notification 2', 'Notification 3']);

  return (
    <BaseLayout
      icons={<LayoutIcons notifications={notifications}/>}
      sidebar={<LayoutSidebar/>}
    >
      {props.children}
    </BaseLayout>
  );
};
