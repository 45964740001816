import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

type SessionContainerProps = {
  title: string;
};

const useStyles = createUseStyles({
  SessionContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 35,
    borderRadius: 8,
    boxShadow: '0px 0px 10px 2px rgb(0, 0, 0, 0.05)',
    width: 350,
  },
  Title: {
    fontSize: 22,
    fontWeight: '700',
    textAlign: 'center',
  },
});

export const SessionContainer: FC<SessionContainerProps> = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.SessionContainer}>
      <Typography gutterBottom align={'center'} variant={'h4'} component={'h2'}>{props.title}</Typography>
      {props.children}
    </div>
  );
};
