import { SessionStorageDataProvider } from '~/common/session';
import { useLocalStorageRepository } from '~/di';

const JWT_KEY = 'JWT';

export class LocalSessionStorageDataProvider implements SessionStorageDataProvider {
  localStorageRepository = useLocalStorageRepository();

  setJwt(jwt: string): void {
    this.localStorageRepository.setItem(JWT_KEY, jwt);
  }

  getJwt(): string | null {
    return this.localStorageRepository.getItem(JWT_KEY) || null;
  }

  delJwt(): void {
    this.localStorageRepository.removeItem(JWT_KEY);
  }
}
