import { Logout, PersonAdd, Settings } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
import React, { FC } from 'react';

type ProfileMenuProps = {
  onClickLogout: () => void;
};

export const ProfileMenu: FC<ProfileMenuProps> = (props) => (
  <>
    <MenuItem onClick={props.onClickLogout}>
      <ListItemIcon>
        <Logout fontSize={'small'}/>
      </ListItemIcon>
      Logout
    </MenuItem>
  </>
);
