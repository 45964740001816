import React from 'react';
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  Container,
} from '@mui/material';

export const SessionLayout: React.FC = (props) => (
  <Box sx={{ flexGrow: 1 }}>
    <CssBaseline/>
    <AppBar position={'static'}>
      <Toolbar>
        <Typography
          variant={'h6'}
          noWrap
          component={'div'}
          sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
        >
          LAZLE HEALTH APP ADMIN
        </Typography>
      </Toolbar>
    </AppBar>
    <Container>
      {props.children}
    </Container>
  </Box>
);
