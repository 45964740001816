import React, { useState, VFC } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SessionSignInRequest } from '../common';
import { useSessionService } from '../di';
import { SessionContainer } from './session-container';
import { SessionLayout } from './session-layout';

const INIT_VALUES = {
  email: '',
  password: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Passowrd is required'),
});

const useStyles = createUseStyles({
  SessionLogin: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 64px)',
  },
});

export const SessionLogin: VFC = () => {
  const [loginError, setLoginError] = useState('');
  const styles = useStyles();
  const authService = useSessionService();

  const onSubmit = (values: SessionSignInRequest) => {
    setLoginError('');
    if (values.email === '' || values.password === '') return;

    authService.signIn(values)
      .catch((error) => setLoginError(error.message));
  };

  return (
    <SessionLayout>
      <div className={styles.SessionLogin}>
        <SessionContainer title={'Authorization'}>
          <Formik
            initialValues={INIT_VALUES}
            onSubmit={onSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {(fmk) => (
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <TextField
                    error={
                      (fmk.errors.email && fmk.touched.email)
                      || !!loginError
                    }
                    id={'email'}
                    label={'Email'}
                    value={fmk.values.email}
                    onChange={fmk.handleChange}
                    helperText={
                      (fmk.errors.email && fmk.touched.email)
                      && fmk.errors.email
                    }
                  />
                  <TextField
                    error={
                      (fmk.errors.password && fmk.touched.password)
                      || !!loginError
                    }
                    type={'password'}
                    id={'password'}
                    label={'Password'}
                    value={fmk.values.password}
                    onChange={fmk.handleChange}
                    helperText={
                      ((fmk.errors.password && fmk.touched.password)
                      && fmk.errors.password)
                      || loginError
                    }
                  />
                </Stack>
                <Stack spacing={2}>
                  <Button
                    onClick={() => fmk.handleSubmit()}
                    variant={'contained'}
                  >
                    Login
                  </Button>
                </Stack>
              </Stack>
            )}
          </Formik>
        </SessionContainer>
      </div>
    </SessionLayout>
  );
};
