import axios from 'axios';
import {
  AddDocumentRequest,
  EditDocumentRequest,
  EditFileResponse,
  DocumentsRequest,
  DocumentsResponse,
  JsonRPCResponse,
  RemoveDocumentRequest,
  OneDocumentRequest,
  DocumentElement,
} from '~/common';
import { makeApiEndpoint } from '~/utils';

export class DocumentsRepository {
  async getDocuments(params: DocumentsRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<DocumentsResponse>>(
      makeApiEndpoint('v1/settings/documents'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get documents error');
    }

    return response.data.result;
  }

  async getDocument(params: OneDocumentRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<DocumentElement>>(
      makeApiEndpoint('v1/settings/documents/one'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get document error');
    }

    return response.data.result;
  }

  async addDocument(params: AddDocumentRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/documents/add'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Add documents error');
    }

    return response.data.result;
  }

  async editDocument(params: EditDocumentRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/documents/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Edit Documents error');
    }

    return response.data.result;
  }

  async removeDocument(params: RemoveDocumentRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/documents/remove'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Remove documents error');
    }

    return response.data.result;
  }

  async editFile(formData: FormData, jwt: string): Promise<string> {
    const response = await axios.post<EditFileResponse>(
      makeApiEndpoint('admin/settings/documents/file/edit'),
      formData,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.status) {
      throw new Error(response.data.message || 'Edit file error');
    }

    return response.data.data.url;
  }
}
