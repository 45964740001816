import { getDependency, registerDependency } from '~/common/di';
import { NotifyService } from '~/common/notify/services';
import {
  ApiSessionAuthDataProvider,
  CurrenciesCrudDataProvider,
  LocalSessionStorageDataProvider,
  SpecialistsCrudDataProvider,
  DocumentsCrudDataProvider,
  UsersCrudDataProvider,
  DevicesCrudDataProvider,
} from '~/providers';
import {
  AuthRepository,
  LocalStorageRepository,
  SpecialistsRepository,
  DocumentsRepository,
  UsersRepository,
  SettingsRepository,
  StatsRepository,
  DevicesRepository,
} from '~/repositories';
import {
  DeviceService,
  SettingsService,
  UsersService,
  StatsService,
  UrlParamsService,
  DocumentsService,
} from './services';

registerDependency('AUTH_REPOSITORY', () => new AuthRepository());
registerDependency('LOCAL_STORAGE_REPOSITORY', () => new LocalStorageRepository());
registerDependency('SPECIALISTS_REPOSITORY', () => new SpecialistsRepository());
registerDependency('DOCUMENTS_REPOSITORY', () => new DocumentsRepository());
registerDependency('USERS_REPOSITORY', () => new UsersRepository());
registerDependency('DEVICES_REPOSITORY', () => new DevicesRepository());
registerDependency('SETTINGS_REPOSITORY', () => new SettingsRepository());
registerDependency('STATS_REPOSITORY', () => new StatsRepository());

registerDependency('SESSION_STORAGE_DATA_PROVIDER', () => new LocalSessionStorageDataProvider());
registerDependency('SESSION_AUTH_DATA_PROVIDER', () => new ApiSessionAuthDataProvider());
registerDependency('SPECIALISTS_CRUD_DATA_PROVIDER', () => new SpecialistsCrudDataProvider());
registerDependency('CURRENCIES_CRUD_DATA_PROVIDER', () => new CurrenciesCrudDataProvider());
registerDependency('DOCUMENTS_CRUD_DATA_PROVIDER', () => new DocumentsCrudDataProvider());
registerDependency('USERS_CRUD_DATA_PROVIDER', () => new UsersCrudDataProvider());
registerDependency('DEVICES_CRUD_DATA_PROVIDER', () => new DevicesCrudDataProvider());

registerDependency('NOTIFY_SERVICE', () => new NotifyService());
registerDependency('DEVICE_SERVICE', () => new DeviceService());
registerDependency('SETTINGS_SERVICE', () => new SettingsService());
registerDependency('USERS_SERVICE', () => new UsersService());
registerDependency('STATS_SERVICE', () => new StatsService());
registerDependency('URL_SERVICE', () => new UrlParamsService());
registerDependency('DOCUMENTS_SERVICE', () => new DocumentsService());

export const useAuthRepository = () => getDependency<AuthRepository>('AUTH_REPOSITORY');
export const useLocalStorageRepository = () => getDependency<LocalStorageRepository>('LOCAL_STORAGE_REPOSITORY');
export const useSpecialistsRepository = () => getDependency<SpecialistsRepository>('SPECIALISTS_REPOSITORY');
export const useDocumentsRepository = () => getDependency<DocumentsRepository>('DOCUMENTS_REPOSITORY');
export const useUsersRepository = () => getDependency<UsersRepository>('USERS_REPOSITORY');
export const useDevicesRepositry = () => getDependency<DevicesRepository>('DEVICES_REPOSITORY');
export const useSettingsRepository = () => getDependency<SettingsRepository>('SETTINGS_REPOSITORY');
export const useStatsRepository = () => getDependency<StatsRepository>('STATS_REPOSITORY');

export const useSpecialistsCrudDataProvider = () => getDependency<SpecialistsCrudDataProvider>('SPECIALISTS_CRUD_DATA_PROVIDER');
export const useCurrenciesCrudDataProvider = () => getDependency<CurrenciesCrudDataProvider>('CURRENCIES_CRUD_DATA_PROVIDER');
export const useDocumentsCrudDataProvider = () => getDependency<DocumentsCrudDataProvider>('DOCUMENTS_CRUD_DATA_PROVIDER');
export const useUsersCrudDataProvider = () => getDependency<UsersCrudDataProvider>('USERS_CRUD_DATA_PROVIDER');
export const useDevicesCrudDataProvider = () => getDependency<DevicesCrudDataProvider>('DEVICES_CRUD_DATA_PROVIDER');

export const useNotifyService = () => getDependency<NotifyService>('NOTIFY_SERVICE');
export const useDeviceService = () => getDependency<DeviceService>('DEVICE_SERVICE');
export const useSettingsService = () => getDependency<SettingsService>('SETTINGS_SERVICE');
export const useUsersService = () => getDependency<UsersService>('USERS_SERVICE');
export const useStatsService = () => getDependency<StatsService>('STATS_SERVICE');
export const useUrlParamsService = () => getDependency<UrlParamsService>('URL_SERVICE');
export const useDocumentsService = () => getDependency<DocumentsService>('DOCUMENTS_SERVICE');
