import axios from 'axios';
import {
  JsonRPCResponse,
  AppSettingRequest,
  SettingsResponse,
  ContactsRequest,
} from '~/common';
import { makeApiEndpoint } from '~/utils';

export class SettingsRepository {
  async changePosition(params: AppSettingRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/app-settings/main-elements/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Change position error');
    }

    return response.data.result;
  }

  async getSettings() {
    const response = await axios.post<JsonRPCResponse<SettingsResponse>>(
      makeApiEndpoint('v1/settings/app-settings'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get settings error');
    }

    return response.data.result;
  }

  async editContacts(params: ContactsRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/app-settings/contacts/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get editValue error');
    }

    return response.data.result;
  }
}
