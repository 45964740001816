import React from 'react';
import { CrudCreateViewConfig } from '../common';

export type CrudCreateBaseViewProps = {
  config: CrudCreateViewConfig;
  form: React.VFC<{ save?: (data: object) => Promise<void>; }>;
};

export const CrudCreateBaseView: React.VFC<CrudCreateBaseViewProps> = (props) => (
  <div style={{ padding: 10 }}>
    <props.form
      save={props.config.create}
    />
  </div>
);
