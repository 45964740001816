import {
  IconButton, InputBase, Paper, Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { useUrlParamsService } from '~/di';

export const UsersToolbar = () => {
  const urlParamsService = useUrlParamsService();
  const [hasLowLength, setLowLength] = useState(false);
  const [value, setValue] = useState(urlParamsService.readUrlParams()?.search || '');

  useEffect(() => {
    if (hasLowLength) setLowLength(false);
  }, [value]);

  const handleSearch: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (value.length < 3 && value.length !== 0) {
      setLowLength(true);
      return;
    }
    urlParamsService.setUrlParams({
      limit: 10,
      offset: 0,
      search: value,
    });
  };

  return (
    <>
      <Paper
        component={'form'}
        sx={{
          p: '2px 4px', display: 'flex', alignItems: 'center', margin: 1,
        }}
        onSubmit={handleSearch}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={'Search user'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <IconButton type={'submit'} sx={{ p: '10px' }} aria-label={'search'}>
          <SearchIcon/>
        </IconButton>
      </Paper>
      {hasLowLength && (
        <Typography sx={{ p: '0 10px' }} color={'red'}>
          Minimum 3 letters
        </Typography>
      )}
    </>
  );
};
