/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { TextField } from '@mui/material';
import { ContactsElement } from '~/common';

interface IAppSettingsContactsProps {
  contacts: ContactsElement[];
  setContacts: (newContacts: ContactsElement[]) => void;
  editContacts: (id: string, value: string) => void;
}

export const AppSettingsContacts: React.VFC<IAppSettingsContactsProps> = ({
  contacts,
  editContacts,
  setContacts,
}) => {
  const handleChange = (id: string, value: string) => {
    const newContacts = contacts.map(
      (contact) => (contact.id === id ? { ...contact, value } : contact),
    );
    setContacts(newContacts);
    editContacts(id, value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {contacts.map((contact) => (
        <TextField
          id={contact.title}
          label={contact.title}
          value={contact.value || ''}
          onChange={(e) => handleChange(contact.id, e.target.value)}
          key={contact.id}
          style={{ marginTop: 15, width: 350 }}
        />
      ))}
    </div>
  );
};
