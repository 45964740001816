import { MenuLink } from './menu-link';

export const menuLinks: Array<MenuLink> = [
  {
    title: 'App Settings',
    url: '/app-settings',
  },
  {
    title: 'Devices',
    url: '/devices',
  },
  {
    title: 'Users',
    url: '/users',
  },
  {
    title: 'Documents',
    url: '/documents',
  },
];
