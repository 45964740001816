import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { BaseLayoutMenuButton } from './base-layout-menu-button';
import { BaseLayoutMenuMobile } from './base-layout-menu-mobile';

export type BaseLayoutProps = {
  icons: React.ReactNode;
  sidebar: React.ReactNode;
};

export const BaseLayout: React.FC<BaseLayoutProps> = (props) => {
  const [visibleSidebar, setVisibleSidebar] = useState<boolean>(true);
  const [visibleMobileSidebar, setVisibleMobileSidebar] = useState<boolean>(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline/>
      <AppBar position={'static'}>
        <Toolbar>
          <BaseLayoutMenuButton
            isShown={visibleSidebar}
            onClickMenu={() => {
              setVisibleMobileSidebar(!visibleMobileSidebar);
            }}
            onClickArrow={() => {
              setVisibleSidebar(!visibleSidebar);
            }}
          />
          <Typography
            variant={'h6'}
            noWrap
            component={'div'}
            sx={{ flexGrow: 1, display: { sm: 'none', md: 'block' } }}
          >
            LAZLE HEALTH APP ADMIN
          </Typography>
          {props.icons}
        </Toolbar>
      </AppBar>
      {visibleMobileSidebar
      && (
        <BaseLayoutMenuMobile
          handleCloseSidebar={() => {
            setVisibleMobileSidebar(false);
          }}
        >
          {props.sidebar}
        </BaseLayoutMenuMobile>
      )}
      <Container style={{ marginLeft: 0 }}>
        <Grid container spacing={2}>
          <Grid
            item
            sx={{ display: { sm: 'none', md: 'block' } }}
            md={3}
          >
            {props.sidebar}
          </Grid>
          <Grid
            item
            sm={12}
            md={9}
            style={{ paddingLeft: 32 }}
          >
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
