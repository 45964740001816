import { SessionService, useSessionService } from '~/common/session';
import { useSettingsRepository } from '~/di';
import { SettingsRepository } from '~/repositories';

export class SettingsService {
  sessionService: SessionService;

  settingsRepository: SettingsRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.settingsRepository = useSettingsRepository();

    this.getSettings = this.getSettings.bind(this);
    this.editContacts = this.editContacts.bind(this);
    this.changePosition = this.changePosition.bind(this);
  }

  async editContacts(id: string, value: string) {
    const res = await this.settingsRepository.editContacts(
      { id, value },
      this.sessionService.jwt$.value,
    );

    return res;
  }

  async getSettings() {
    const settings = await this.settingsRepository.getSettings();

    return settings;
  }

  async changePosition(id: string, orderNumber: number) {
    const res = await this.settingsRepository.changePosition(
      { id, orderNumber },
      this.sessionService.jwt$.value,
    );

    return res;
  }
}
