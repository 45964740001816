import React, { useEffect, useRef, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import {
  Button, Divider, Stack, TextField,
} from '@mui/material';
import { AddDocumentRequest } from '~/common';

interface ICreateDocumentCrud {
  save: (data: Partial<AddDocumentRequest & {file: any;}>)
    => Promise<void>;
}

export const CreateDocumentCrud: React.FC<ICreateDocumentCrud> = (props) => {
  const fileInputRef = useRef<HTMLInputElement>();
  const fileRef = useRef<File>(null);
  const [showName, setShowName] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    onSubmit: (values) => {
      props.save({
        title: values.title,
        file: fileRef.current,
      });
    },
  });

  const handleChangeFile = async (event: any) => {
    if (!event) return;

    fileRef.current = event.target.files[0] as File;
    setShowName(true);
  };

  useEffect(() => {
    console.log(fileInputRef.current.value);
  }, [fileInputRef]);

  return (
    <FormikProvider value={formik}>
      <Stack spacing={4}>
        <TextField
          sx={{ width: '50%' }}
          label={'Document Title'}
          value={formik.values.title}
          onChange={formik.handleChange}
          id={'title'}
        />
        <Button
          onClick={() => fileInputRef?.current.click()}
          sx={{ width: '30%' }}
          variant={'contained'}
        >
          Select file
        </Button>
        {
          showName && (
            <span>
              {fileInputRef.current?.value.split(/(\\|\/)/g).pop()}
            </span>
          )
        }
        <input
          ref={fileInputRef}
          type={'file'}
          onChange={handleChangeFile}
          style={{ display: 'none' }}
        />
        <Divider/>
        <Button
          variant={'contained'}
          onClick={() => formik.handleSubmit()}
          sx={{ width: '30%' }}
        >
          Add
        </Button>
      </Stack>
    </FormikProvider>
  );
};
