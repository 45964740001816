import axios from 'axios';
import { ApiResponse } from '~/common/api';
import {
  DeleteSpecialistsParams,
  DeleteSpecialistsResponse,
  GetSpecialistsQuery,
  GetSpecialistsResponse,
  PostSpecialistsRequest,
  PostSpecialistsResponse,
  PostSpecialistsEducationsParams,
  PostSpecialistsEducationsRequest,
  PostSpecialistsEducationsResponse,
  PostSpecialistsHightEducationsParams,
  PostSpecialistsHightEducationsRequest,
  PostSpecialistsHightEducationsResponse,
  PostSpecialistsWorkingExperiencesParams,
  PostSpecialistsWorkingExperiencesRequest,
  PostSpecialistsWorkingExperiencesResponse,
  PutSpecialistsParams,
  PutSpecialistsRequest,
  PutSpecialistsResponse,
  PutSpecialistsToggleArchivedParams,
  PutSpecialistsToggleArchivedResponse,
} from '~/common/requests';
import { makeJwtHeaders, makeApiEndpoint } from '~/utils';

export class SpecialistsRepository {
  // DELETE:api/v1/specialists/:id

  async deleteSpecialists(
    params: DeleteSpecialistsParams,
    jwt: string,
  ): Promise<DeleteSpecialistsResponse> {
    const response = await axios.delete<ApiResponse<DeleteSpecialistsResponse>>(
      makeApiEndpoint('specialistRequests/:id', params),
      makeJwtHeaders(jwt),
    );

    console.log('deleteSpecialistsRequest >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // GET:api/v1/specialists

  async getSpecialists(query: GetSpecialistsQuery, jwt: string): Promise<GetSpecialistsResponse> {
    const response = await axios.get<ApiResponse<{ specialists: GetSpecialistsResponse; }>>(
      makeApiEndpoint('specialistRequests', null, query),
      makeJwtHeaders(jwt),
    );

    console.log('getSpecialists >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }

    return response.data.data.specialists || [];
  }

  // POST:api/v1/specialists

  async postSpecialists(
    request: PostSpecialistsRequest,
    jwt: string,
  ): Promise<PostSpecialistsResponse> {
    const response = await axios.post<ApiResponse<PostSpecialistsResponse>>(
      makeApiEndpoint('specialists'),
      { specialist: request },
      makeJwtHeaders(jwt),
    );

    console.log('postSpecialists >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // POST:api/v1/specialists/:id/educations

  async postSpecialistsEducations(
    params: PostSpecialistsEducationsParams,
    request: PostSpecialistsEducationsRequest,
    jwt: string,
  ): Promise<PostSpecialistsEducationsResponse> {
    const response = await axios.post<ApiResponse<PostSpecialistsEducationsResponse>>(
      makeApiEndpoint('specialists/:id/educations', params),
      { educations: request },
      makeJwtHeaders(jwt),
    );

    console.log('postSpecialistsEducations >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // POST:api/v1/specialists/:id/hightEducations

  async postSpecialistsHightEducations(
    params: PostSpecialistsHightEducationsParams,
    request: PostSpecialistsHightEducationsRequest,
    jwt: string,
  ): Promise<PostSpecialistsHightEducationsResponse> {
    const response = await axios.post<ApiResponse<PostSpecialistsHightEducationsResponse>>(
      makeApiEndpoint('specialists/:id/hightEducations', params),
      { hightEducations: request },
      makeJwtHeaders(jwt),
    );

    console.log('postSpecialistsHightEducations >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // POST:api/v1/specialists/:id/workingExperiences

  async postSpecialistsWorkingExperiences(
    params: PostSpecialistsWorkingExperiencesParams,
    request: PostSpecialistsWorkingExperiencesRequest,
    jwt: string,
  ): Promise<PostSpecialistsWorkingExperiencesResponse> {
    const response = await axios.post<ApiResponse<PostSpecialistsWorkingExperiencesResponse>>(
      makeApiEndpoint('specialists/:id/workingExperiences', params),
      { workingExperiences: request },
      makeJwtHeaders(jwt),
    );

    console.log('postSpecialistsWorkingExperiences >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // PUT:api/v1/specialists/:id

  async putSpecialists(
    params: PutSpecialistsParams,
    request: PutSpecialistsRequest,
    jwt: string,
  ): Promise<PutSpecialistsResponse> {
    const response = await axios.put<ApiResponse<PutSpecialistsResponse>>(
      makeApiEndpoint('specialists/:id', params),
      { specialist: request },
      makeJwtHeaders(jwt),
    );

    console.log('putSpecialists >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }

  // PUT:api/v1/specialists/:id/toggleArchived

  async putSpecialistsToggleArchived(
    params: PutSpecialistsToggleArchivedParams,
    jwt: string,
  ): Promise<PutSpecialistsToggleArchivedResponse> {
    const response = await axios.put<ApiResponse<PutSpecialistsToggleArchivedResponse>>(
      makeApiEndpoint('specialists/:id/toggleArchived', params),
      makeJwtHeaders(jwt),
    );

    console.log('putSpecialistsToggleArchived >>>', response);

    if (response.data.message !== 'OK') {
      throw new Error(response.data.message);
    }
  }
}
