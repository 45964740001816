import React from 'react';
import {
  Chart, ArcElement, Legend, CategoryScale, LinearScale, BarElement,
} from 'chart.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Router } from './router';
import { Session } from '~/common/session';

Chart.register(
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#5ABEB6',
      contrastText: '#FFFFFF',
    },
  },
});

export const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <Session>
      <Router/>
    </Session>
  </ThemeProvider>
);
