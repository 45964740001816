import axios from 'axios';
import {
  JsonRPCResponse,
  UserEditRequest,
  UserInfoRequest,
  UserInfoResponse,
  UserRemoveRequest,
  UsersListRequest,
  UsersListResponse,
  UsersStatResponse,
} from '~/common';
import { makeApiEndpoint } from '~/utils';

export class UsersRepository {
  async getStat(jwt: string) {
    const response = await axios.post<JsonRPCResponse<UsersStatResponse>>(
      makeApiEndpoint('admin/users/stat'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get usersStat error');
    }

    return response.data.result;
  }

  async getList(params: UsersListRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<UsersListResponse>>(
      makeApiEndpoint('admin/users/list'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get UsersList error');
    }

    return response.data.result;
  }

  async getInfo(params: UserInfoRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<UserInfoResponse>>(
      makeApiEndpoint('admin/users/info'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get UserInfo error');
    }

    return response.data.result;
  }

  async editUser(params: UserEditRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/users/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Edit user error');
    }

    return response.data.result;
  }

  async removeUser(params: UserRemoveRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/users/remove'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Remove user error');
    }

    return response.data.result;
  }
}
