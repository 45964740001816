import { SessionService, useSessionService } from '~/common/session';
import { useDocumentsRepository } from '~/di';
import { DocumentsRepository } from '~/repositories';

export class DocumentsService {
  sessionService: SessionService;

  documentsRepository: DocumentsRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.documentsRepository = useDocumentsRepository();
  }

  async deleteDocument(id: string) {
    await this.documentsRepository.removeDocument(
      { id },
      this.sessionService.jwt$.value,
    );
  }
}
