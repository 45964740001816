/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { DraggableBlock } from '~/common/ui';
import { MainElement } from '~/common';
import { reorder } from '~/utils';

interface IAppSettingsMainElementsProps {
  mainElements: MainElement[];
  setMainElements: (newValue: MainElement[]) => void;
  changePosition: (id: string, orderNumber: number) => any;
}

export const AppSettingsMainElements: React.VFC<IAppSettingsMainElementsProps> = ({
  changePosition,
  mainElements,
  setMainElements,
}) => {
  const handleDragEvent: OnDragEndResponder = (result) => {
    setMainElements(reorder(
      mainElements,
      result.source.index,
      result.destination.index,
    ));
    changePosition(result.draggableId, result.destination.index + 1);
  };

  return (
    <DragDropContext onDragEnd={handleDragEvent}>
      <Droppable droppableId={'droppable'}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ marginTop: 15 }}
          >
            {mainElements.map((item, index) => (
              <DraggableBlock
                item={item}
                index={index}
                key={item.id}
                list={mainElements}
                setList={setMainElements}
                changePosition={changePosition}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
