import { UserInfoResponse, UserItem } from '~/common';
import { CrudDataProvider, CrudDataProviderList } from '~/common/crud/common';
import { SessionService, useSessionService } from '~/common/session';
import { useUsersRepository } from '~/di';
import { UsersRepository } from '~/repositories';

export class UsersCrudDataProvider implements CrudDataProvider {
  sessionService: SessionService;

  usersRepository: UsersRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.usersRepository = useUsersRepository();
  }

  async getList(limit: number, offset: number, search = ''): Promise<CrudDataProviderList<UserItem>> {
    const res = await this.usersRepository.getList(
      { limit, offset, search: search || undefined },
      this.sessionService.jwt$.value,
    );
    return {
      list: res.list,
      total: res.count,
    };
  }

  async createItem(): Promise<void> {
    return Promise.resolve(undefined);
  }

  async updateItem(item: UserItem): Promise<void> {
    await this.usersRepository.editUser(
      item,
      this.sessionService.jwt$.value,
    );
  }

  async getItem(id: string): Promise<UserInfoResponse> {
    const res = await this.usersRepository.getInfo(
      { id },
      this.sessionService.jwt$.value,
    );

    return res;
  }
}
