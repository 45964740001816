import React, { useCallback } from 'react';
import { DocumentElement } from '~/common';
import {
  Crud,
  CrudCreateBaseView,
  CrudCreateViewConfig,
  CrudEditBaseView,
  CrudEditViewConfig,
  CrudIndexDataGridToolbar,
  CrudIndexDataGridView,
  CrudIndexViewConfig,
} from '~/common/crud';
import { useDocumentsCrudDataProvider } from '~/di';
import { CreateDocumentCrud } from './create-document-crud';
import { EditDocumentCrud } from './edit-document-crud';
import { Layout } from './layout';

export const DocumentsCrud: React.VFC = () => {
  const documentsCrudDataProvider = useDocumentsCrudDataProvider();
  CrudIndexDataGridToolbar.defaultProps = {
    addButtonText: 'ADD DOCUMENT',
    listTitle: 'Documents list',
  };

  const indexView = useCallback((config: CrudIndexViewConfig) => (
    <CrudIndexDataGridView
      config={config}
      toolbar={CrudIndexDataGridToolbar}
      columns={[
        {
          field: 'title',
          headerName: 'Title',
          flex: 2,
        },
      ]}
      rowsPerPageOptions={[10, 20]}
    />
  ), []);

  const createView = useCallback((config: CrudCreateViewConfig<DocumentElement>) => (
    <CrudCreateBaseView
      config={config}
      form={CreateDocumentCrud}
    />
  ), []);

  const editView = useCallback((config: CrudEditViewConfig<DocumentElement>) => (
    <CrudEditBaseView
      config={config}
      form={EditDocumentCrud}
    />
  ), []);

  return (
    <Layout>
      <Crud
        crudDataProvider={documentsCrudDataProvider}
        indexView={indexView}
        createView={createView}
        editView={editView}
        title={'Documents'}
        endpoint={'documents'}
      />
    </Layout>
  );
};
