import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { CrudDataProviderList, CrudIndexViewConfig } from '~/common/crud/common';
import { usePromise } from '~/utils';
import { DeviceElement } from '~/common';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    width: 300,
    maxHeight: 360,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
});

const defaultResult: CrudDataProviderList<DeviceElement> = { list: [], total: 0 };

type CrudIndexDataGridViewProps = {
  config: CrudIndexViewConfig;
};

export const DeviceCrudIndexView: React.VFC<CrudIndexDataGridViewProps> = (props) => {
  const styles = useStyles();
  const navigation = useNavigate();
  const page = 0;
  const pageSize = 10;

  const data = usePromise(
    () => props.config.dataProvider.getList(pageSize, page * pageSize),
    [page, pageSize],
  );

  const result: CrudDataProviderList<DeviceElement> = data?.result || defaultResult;

  return (
    <div className={styles.container}>
      {result.list.map((device) => (
        <Card
          className={styles.card}
          key={device.id}
          sx={{ margin: 1 }}
          onClick={() => navigation(`edit/${device.id}`)}
        >
          {
            device.urlImg ? (
              <CardMedia
                component={'img'}
                height={'200'}
                image={device.urlImg}
                alt={'device'}
              />
            ) : (
              <div style={{ height: 200, backgroundColor: '#5ABEB6' }}/>
            )
          }
          <CardContent>
            <Typography
              gutterBottom
              variant={'h5'}
              component={'div'}
              noWrap
            >
              {device.title}
            </Typography>
            {device.description ? (
              <Typography
                variant={'body2'}
                color={'text.secondary'}
                noWrap
              >
                {device.description?.length < 80
                  ? device.description
                  : `${device.description?.slice(0, 80 - 1)}...`}
              </Typography>
            ) : (
              <Typography variant={'body2'} color={'gray'} fontStyle={'italic'}>
                None description
              </Typography>
            )}
          </CardContent>
        </Card>

      ))}
    </div>
  );
};
