import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DeviceElement } from '~/common';
import { useDeviceService } from '~/di';

interface IDeviceEditView {
  item: DeviceElement;
  save: any;
}

export const DeviceEditView: React.FC<IDeviceEditView> = (props) => {
  const [intialValues, setInitialValues] = useState<DeviceElement>();
  const [fileList, setFileList] = useState<DeviceElement['files']>([]);
  const [urlImage, setUrlImage] = useState<DeviceElement['urlImg']>(null);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [activeDocId, setActiveDocId] = useState('');
  const [titles, setTitles] = useState<{ [propName: string]: string; }>({});
  const [activeTitle, setActiveTitle] = useState('');

  const fileInputRef = useRef<HTMLInputElement>();
  const imageInputRef = useRef<HTMLInputElement>();
  const fileRef = useRef<File>(null);

  const deviceService = useDeviceService();

  useEffect(() => {
    if (props.item) {
      setInitialValues({
        createdAt: props.item.createdAt,
        description: props.item.description,
        files: props.item.files,
        id: props.item.id,
        title: props.item.title,
        type: props.item.type,
        updatedAt: props.item.updatedAt,
      });
      setFileList(props.item.files);
      setUrlImage(props.item.urlImg);

      const titlesObj: { [propName: string]: string; } = {};
      props.item.files.forEach((file) => {
        titlesObj[file.id] = file.title;
      });
      setTitles(titlesObj);
    }
  }, [props.item]);

  const getNewTitles = (id: string, title: string) => {
    const newTitles: { [propName: string]: string; } = { ...titles };
    newTitles[id] = title;
    return newTitles;
  };

  const handleChangeFile = async (event: any) => {
    if (!event) return;

    const file: File = event.target.files[0];
    fileRef.current = file as File;

    const fileName = file.name.split('.')[0];

    const { id, urlFile } = await deviceService.addFile({
      title: fileName,
      deviceId: props.item.id,
      file,
    });
    setFileList([...fileList, {
      title: fileName,
      id,
      urlFile,
    }]);

    setTitles(getNewTitles(id.toString(), fileName));
  };

  const handleChangeImage = async (event: any) => {
    if (!event) return;

    const file: File = event.target.files[0];
    fileRef.current = file as File;

    const url = await deviceService.editImage({
      deviceId: props.item.id,
      file,
    });

    setUrlImage(url);
  };

  const handleDeleteFile = () => {
    deviceService.deleteFile(activeDocId);
    setFileList(fileList.filter((file) => file.id !== activeDocId));
    setVisibleDelete(false);
  };

  const handleDeleteClick = (id: string) => {
    setActiveDocId(id);
    setVisibleDelete(true);
  };

  const handleSaveDocEdit = () => {
    setVisibleEdit(false);
    setTitles(getNewTitles(activeDocId, activeTitle));
    deviceService.editTitle({ id: activeDocId, title: activeTitle });
  };

  return (
    <>
      {intialValues && (
      <Formik
        initialValues={intialValues}
        onSubmit={props.save}
      >
        {(fmk) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  value={fmk.values.title}
                  onChange={fmk.handleChange}
                  id={'title'}
                  label={'Title'}
                  style={{ width: 400 }}
                />
                <TextField
                  value={fmk.values.description}
                  onChange={fmk.handleChange}
                  id={'description'}
                  label={'Description'}
                  style={{ marginTop: 15, width: 400 }}
                  multiline
                />
                <TableContainer component={Paper} style={{ marginTop: 15 }}>
                  <Table sx={{ minWidth: 300 }} aria-label={'simple table'}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Docs title</TableCell>
                        {!!fileList.length && (
                          <>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileList.length ? fileList.map((file) => (
                        <TableRow
                          key={file.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component={'th'} scope={'row'}>
                            {titles[file.id]}
                          </TableCell>
                          <TableCell align={'right'} style={{ width: 1, padding: 2 }}>
                            <IconButton onClick={() => window.open(file.urlFile)}>
                              <VisibilityIcon/>
                            </IconButton>
                          </TableCell>
                          <TableCell align={'right'} style={{ width: 1, padding: 2 }}>
                            <IconButton
                              onClick={() => {
                                setVisibleEdit(true);
                                setActiveDocId(file.id);
                                setActiveTitle(titles[file.id]);
                              }}
                            >
                              <EditIcon/>
                            </IconButton>
                          </TableCell>
                          <TableCell align={'right'} style={{ width: 1, paddingLeft: 2 }}>
                            <IconButton onClick={() => handleDeleteClick(file.id)}>
                              <ClearIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )) : (
                        <Typography style={{ textAlign: 'center', padding: 5 }}>
                          Empty
                        </Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <input
                  ref={fileInputRef}
                  type={'file'}
                  onChange={handleChangeFile}
                  style={{ display: 'none' }}
                />
                <Button
                  onClick={() => fileInputRef.current.click()}
                  style={{ marginTop: 15 }}
                  variant={'outlined'}
                >
                  Add document
                </Button>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                  alt={'device'}
                  onChange={handleChangeImage}
                  style={{
                    width: 200,
                    height: 250,
                    backgroundColor: 'gray',
                    objectFit: 'cover',
                  }}
                  src={urlImage}
                />
                <Button
                  onClick={() => imageInputRef.current.click()}
                  style={{ marginTop: 15 }}
                  variant={'contained'}
                >
                  Edit IMG.
                </Button>
              </div>
            </div>
            <input
              ref={imageInputRef}
              type={'file'}
              onChange={handleChangeImage}
              style={{ display: 'none' }}
            />
            <Button
              onClick={() => fmk.handleSubmit()}
              variant={'contained'}
              style={{ width: 400, alignSelf: 'center', marginTop: 32 }}
            >
              Save
            </Button>
            <Dialog
              open={visibleEdit}
              onClose={() => setVisibleEdit(false)}
              aria-labelledby={'alert-dialog-title'}
              aria-describedby={'alert-dialog-description'}
            >
              <DialogTitle id={'alert-dialog-title'}>
                Edit doc title
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={activeTitle}
                  onChange={(e) => setActiveTitle(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setVisibleEdit(false)}>Cancel</Button>
                <Button onClick={handleSaveDocEdit} autoFocus>Save</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={visibleDelete}
              onClose={() => setVisibleDelete(false)}
              aria-labelledby={'alert-dialog-title'}
              aria-describedby={'alert-dialog-description'}
            >
              <DialogTitle id={'alert-dialog-title'}>
                Delete document
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Do you confirm delete this document?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setVisibleDelete(false)}>Cancel</Button>
                <Button onClick={handleDeleteFile} autoFocus>Delete</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Formik>
      )}
    </>
  );
};
