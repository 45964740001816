import React from 'react';
import { createUseStyles } from 'react-jss';

export type BaseLayoutMenuMobileProps = {
  handleCloseSidebar: () => void;
};

const useStyles = createUseStyles({
  SidebarMobile: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    padding: '25px 0px',
    position: 'absolute',
    top: '58px',
    right: '0px',
    left: '0px',
    down: '0px',
    zIndex: '1',
    backgroundColor: 'white',
    overflow: 'auto',
  },
});

export const BaseLayoutMenuMobile: React.FC<BaseLayoutMenuMobileProps> = (props) => {
  const styles = useStyles();

  return (
    <div onClick={props.handleCloseSidebar} className={styles.SidebarMobile}>
      {props.children}
    </div>
  );
};
