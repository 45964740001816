import React from 'react';
import { CrudEditViewConfig } from '../common';

export type CrudEditBaseViewProps = {
  config: CrudEditViewConfig;
  form: React.VFC<{ item?: object; save?: (data: object) => Promise<void>; }>;
};

export const CrudEditBaseView: React.VFC<CrudEditBaseViewProps> = (props) => (
  <div style={{ padding: 10 }}>
    <props.form
      item={props.config.item}
      save={props.config.update}
    />
  </div>
);
