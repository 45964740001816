import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { usePromise } from '~/utils';
import { CrudDataProvider, CrudEditViewConfig } from '../common';

export type CrudEditProps = {
  crudDataProvider: CrudDataProvider;
  editView: (config: CrudEditViewConfig) => React.ReactNode;
};

export const CrudEdit: React.VFC<CrudEditProps> = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const data = usePromise(() => props.crudDataProvider.getItem(params.id));

  const update = (updateData: object) => new Promise<void>((resolve, reject) => {
    props.crudDataProvider.updateItem(updateData, params.id)
      .then(() => {
        navigate('..');
        resolve(undefined);
      })
      .catch(reject);
  });

  return (
    <Box sx={{ marginY: 2 }}>
      {props.editView({
        update,
        item: data.result,
        loading: data.loading,
      })}
    </Box>
  );
};
