const DependencyCreators = {};

const Dependencies = {};

export function registerDependency(key: string, creator: () => object): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  DependencyCreators[key] = creator;
}

export function getDependency<T>(key: string): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const creator = DependencyCreators[key];

  if (!creator) {
    throw new Error(`${key}: No dependency registered`);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!Dependencies[key]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Dependencies[key] = creator();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Dependencies[key];
}
