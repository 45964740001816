export enum SpecialistGrade {
  JUNIOR = 'Junior',
  JUNIOR_PLUS = 'Junior',
  MIDDLE = 'Middle',
  MIDDLE_PLUS = 'Middle+',
  SENIOR = 'Senior',
  LEAD = 'Lead',
}

export const SpecialistGradeRusDescription: Record<SpecialistGrade, string> = {
  [SpecialistGrade.JUNIOR]: 'Джуниор',
  [SpecialistGrade.JUNIOR_PLUS]: 'Джуниор плюс',
  [SpecialistGrade.MIDDLE]: 'Мидл',
  [SpecialistGrade.MIDDLE_PLUS]: 'Мидл плюс',
  [SpecialistGrade.SENIOR]: 'Сеньор',
  [SpecialistGrade.LEAD]: 'Лид',
};

export const SpecialistGradeEngDescription: Record<SpecialistGrade, string> = {
  [SpecialistGrade.JUNIOR]: 'Junior',
  [SpecialistGrade.JUNIOR_PLUS]: 'Junior plus',
  [SpecialistGrade.MIDDLE]: 'Middle',
  [SpecialistGrade.MIDDLE_PLUS]: 'Middle plus',
  [SpecialistGrade.SENIOR]: 'Senior',
  [SpecialistGrade.LEAD]: 'Lead',
};
