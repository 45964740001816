import React, { VFC } from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuLinks } from '~/common';

export const LayoutSidebar: VFC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleItemClick = (url: string) => () => navigate(url);

  return (
    <List>
      {menuLinks.map((menuLink) => (
        <ListItemButton
          key={menuLink.url}
          onClick={handleItemClick(menuLink.url)}
          selected={pathname === menuLink.url}
        >
          <ListItemIcon>
            <InboxIcon/>
          </ListItemIcon>
          <ListItemText primary={menuLink.title}/>
        </ListItemButton>
      ))}
    </List>
  );
};
