import { Currency } from '~/common';
import { CrudDataProvider } from '~/common/crud/common';

const mockItems: Array<Currency> = [
  {
    id: 'xxx1',
    title: 'Russian Ruble',
    sign: 'RUB',
  },
  {
    id: 'xxx2',
    title: 'US Dollar',
    sign: 'USD',
  },
  {
    id: 'xxx3',
    title: 'GB Pound',
    sign: 'GBP',
  },
  {
    id: 'xxx4',
    title: 'Four Item',
    sign: '4X',
  },
  {
    id: 'xxx5',
    title: 'Five Item',
    sign: '5X',
  },
];

export class CurrenciesCrudDataProvider implements CrudDataProvider<Currency, Currency['id']> {
  constructor() {
    console.log('NEW CurrenciesCrudDataProvider');
  }

  getList(limit: number, offset: number): Promise<{
    list: Array<Currency>;
    total: number;
  }> {
    console.log('CurrenciesCrudDataProvider getList', limit, offset);

    return new Promise((resolve) => {
      setTimeout(() => resolve({
        list: mockItems.slice(offset, limit + offset),
        total: mockItems.length,
      }), 2000);
    });
  }

  getItem(id: Currency['id']): Promise<Currency> {
    console.log('CurrenciesCrudDataProvider getItem', id);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const item = mockItems.find((mockItem) => mockItem.id === id);

        if (item) {
          resolve(item);
        } else {
          reject(new Error('No currency'));
        }
      }, 2000);
    });
  }

  createItem(data: Currency): Promise<void> {
    mockItems.push(data);

    return Promise.resolve(undefined);
  }

  updateItem(data: Currency, id: Currency['id']): Promise<void> {
    console.log('CurrenciesCrudDataProvider updateItem', id, data);
    console.log('mockItems BEFORE >>>', mockItems);
    return new Promise((resolve, reject) => {
      for (let i = 0; i < mockItems.length; i++) {
        if (mockItems[i].id === id) {
          mockItems[i] = {
            ...mockItems[i],
            ...data,
          };

          resolve(undefined);

          return;
        }
      }

      reject(new Error('Item not found'));
    });
    console.log('mockItems AFTER >>>', mockItems);
  }
}
