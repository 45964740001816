import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentElement } from '~/common';
import { useDocumentsService } from '~/di';

interface IEditDocumentCrudProps {
  item?: DocumentElement;
  save?: (data: { id: string; title: string; }) => Promise<void>;
}

export const EditDocumentCrud: React.VFC<IEditDocumentCrudProps> = (props) => {
  const documentsService = useDocumentsService();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [visibleDelete, setVisibleDelete] = useState(false);

  useEffect(() => {
    if (props.item) {
      setTitle(props.item.title);
    }
  }, [props.item]);

  const handleDeleteDoc = () => {
    documentsService.deleteDocument(props.item.id);
    navigate('/documents');
  };

  return (
    <>
      {props.item && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant={'h4'}>
          View document
        </Typography>
        <TextField
          style={{ width: 350, marginTop: 24 }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Button
          style={{ width: 350, marginTop: 16 }}
          onClick={() => window.open(props.item.urlFile)}
          variant={'contained'}
        >
          View document
        </Button>
        <Button
          style={{ width: 350, marginTop: 16 }}
          onClick={() => setVisibleDelete(true)}
          variant={'outlined'}
        >
          Delete document
        </Button>
        <Button
          style={{ width: 350, marginTop: 48 }}
          onClick={() => props.save({ id: props.item.id, title })}
          variant={'contained'}
        >
          Save
        </Button>

        <Dialog
          open={visibleDelete}
          onClose={() => setVisibleDelete(false)}
          aria-labelledby={'alert-dialog-title'}
          aria-describedby={'alert-dialog-description'}
        >
          <DialogTitle id={'alert-dialog-title'}>
            Delete document
          </DialogTitle>
          <DialogContent>
            <DialogContentText id={'alert-dialog-description'}>
              Do you confirm delete this document?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVisibleDelete(false)}>Cancel</Button>
            <Button onClick={handleDeleteDoc} autoFocus>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
      )}
    </>
  );
};
