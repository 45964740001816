import { SessionAuthDataProvider } from '~/common/session';
import { useAuthRepository } from '~/di';
import { AuthSignInRequest } from '~/common/requests';

export class ApiSessionAuthDataProvider implements SessionAuthDataProvider {
  authRepository = useAuthRepository();

  signIn(data: object): Promise<string> {
    return this.authRepository.postSignIn(data as AuthSignInRequest);
  }
}
