import { Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

interface IChartTitleProps {
  title: string;
  value: string | number;
  category: string;
  style?: CSSProperties;
}

const useStyles = createUseStyles({
  title: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
});

export const ChartTitle: React.FC<IChartTitleProps> = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.title} style={props.style}>
      <Typography fontWeight={'bold'}>{props.category}</Typography>
      <Typography>{props.title}: {props.value}</Typography>
    </div>
  );
};
