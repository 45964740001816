import { AccountCircle } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { NotificationMenu } from './notification-menu';
import { Notification } from './notification';
import { ProfileMenu } from './profile-menu';
import { useSessionService } from '~/common/session';

export type LayoutIconsProps = {
  notifications: Array<string>;
};

const useStyles = createUseStyles({
  'LayoutIcons': {},
  '@media (max-width: 899px)': {
    LayoutIcons: {
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
  },
});

export const LayoutIcons: React.FC<LayoutIconsProps> = (props) => {
  const styles = useStyles();
  const sessionService = useSessionService();

  /** FOR PROFILE NOTIFICATIONS */
  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);
  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  /** FOR BELL NOTIFICATIONS */
  const [anchorElNotification, setAnchorElNotification] = React.useState<null | HTMLElement>(null);
  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
  const handleClickLogout = useCallback(() => {
    setVisibleLogout(true);
  }, []);
  const handleCloseDialog = () => {
    setVisibleLogout(false);
  };

  return (
    <>
      <Box
        className={styles.LayoutIcons}
      >
        <IconButton
          size={'large'}
          edge={'end'}
          aria-label={'account of current user'}
          aria-haspopup={'true'}
          color={'inherit'}
          onClick={handleClickProfile}
        >
          <AccountCircle/>
        </IconButton>
      </Box>
      <Notification
        anchorEl={anchorElNotification}
        setAnchorEl={setAnchorElNotification}
        menu={<NotificationMenu notifications={props.notifications}/>}
      />
      <Notification
        anchorEl={anchorElProfile}
        setAnchorEl={setAnchorElProfile}
        menu={<ProfileMenu onClickLogout={handleClickLogout}/>}
      />
      <Dialog
        open={visibleLogout}
        onClose={() => setVisibleLogout(false)}
        aria-labelledby={'alert-dialog-title'}
        aria-describedby={'alert-dialog-description'}
      >
        <DialogTitle id={'alert-dialog-title'}>
          Logout
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={'alert-dialog-description'}>
            Do you confirm logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => sessionService.logout()} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
