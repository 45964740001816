import { SessionService, useSessionService } from '~/common/session';
import { useStatsRepository } from '~/di';
import { StatsRepository } from '~/repositories';

export class StatsService {
  sessionService: SessionService;

  statsRepository: StatsRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.statsRepository = useStatsRepository();

    this.getStats = this.getStats.bind(this);
  }

  async getStats() {
    const stats = await this.statsRepository.getStats(
      this.sessionService.jwt$.value,
    );

    return stats;
  }
}
