import { SessionService, useSessionService } from '~/common/session';
import { useUsersRepository } from '~/di';
import { UsersRepository } from '~/repositories';

export class UsersService {
  sessionService: SessionService;

  usersRepository: UsersRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.usersRepository = useUsersRepository();

    this.deleteUser = this.deleteUser.bind(this);
  }

  async deleteUser(id: string) {
    await this.usersRepository.removeUser(
      { id },
      this.sessionService.jwt$.value,
    );
  }
}
