import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CrudCreateViewConfig, CrudDataProvider } from '../common';

export type CrudCreateProps = {
  crudDataProvider: CrudDataProvider;
  createView: (config: CrudCreateViewConfig) => React.ReactNode;
};

export const CrudCreate: React.VFC<CrudCreateProps> = (props) => {
  const navigate = useNavigate();

  const create = (createData: object) => new Promise<void>((resolve, reject) => {
    props.crudDataProvider.createItem(createData)
      .then(() => {
        navigate('..');
        resolve(undefined);
      })
      .catch(reject);
  });

  return (
    <Box sx={{ marginY: 2 }}>
      {props.createView({
        create,
      })}
    </Box>
  );
};
