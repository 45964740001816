import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { UserInfoResponse } from '~/common';
import { useUsersService } from '~/di';

interface IUsersInfoCrudProps {
  item: UserInfoResponse;
}

const authResource = ['', 'Google account', 'Apple ID', 'Facebook account'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const UsersInfoCrud: React.FC<IUsersInfoCrudProps> = (props) => {
  const userService = useUsersService();
  const navigation = useNavigate();

  const [visibleDelete, setVisibleDelete] = useState(false);

  return (
    <>
      {props.item?.user && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2>{props.item.user.firstname} {props.item.user.lastname}</h2>
        <span>{authResource[props.item.user.type]} ({props.item.user.email})</span>
        <h3>User devices</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label={'simple table'}>
            <TableHead>
              <TableRow>
                <TableCell>Device</TableCell>
                <TableCell>Connection date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.item.userDevices.map((device) => {
                const lastSyncDate = format(new Date(device.lastSync), 'LLLL, d yyyy');
                return (
                  <TableRow
                    key={device.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component={'th'} scope={'row'}>
                      {device.deviceTitle}
                    </TableCell>
                    <TableCell align={'left'}>
                      {lastSyncDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          onClick={() => setVisibleDelete(true)}
          style={{ width: 200, marginTop: 25, alignSelf: 'flex-end' }}
          variant={'outlined'}
        >
          Delete user
        </Button>
        <Dialog
          open={visibleDelete}
          onClose={() => setVisibleDelete(false)}
          aria-labelledby={'alert-dialog-title'}
          aria-describedby={'alert-dialog-description'}
        >
          <DialogTitle id={'alert-dialog-title'}>
            Delete user
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you confirm delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVisibleDelete(false)}>Cancel</Button>
            <Button
              onClick={() => {
                userService.deleteUser(props.item.user.id)
                  .finally(() => navigation('/users'));
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      )}
    </>
  );
};
