import { AddDocumentRequest, DocumentElement } from '~/common';
import { CrudDataProvider, CrudDataProviderList } from '~/common/crud/common';
import { useDocumentsRepository } from '~/di';
import { SessionService, useSessionService } from '~/common/session';
import { DocumentsRepository } from '~/repositories';

export class DocumentsCrudDataProvider implements CrudDataProvider<DocumentElement, DocumentElement['id'], AddDocumentRequest> {
  sessionService: SessionService;

  documentsRepository: DocumentsRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.documentsRepository = useDocumentsRepository();
  }

  async getList(limit: number, offset: number): Promise<CrudDataProviderList<DocumentElement>> {
    const res = await this.documentsRepository.getDocuments(
      { limit, offset },
      this.sessionService.jwt$.value,
    );
    return {
      list: res.list,
      total: res.list.length,
    };
  }

  async createItem(data: Partial<AddDocumentRequest> & {file: any;}): Promise<void> {
    const id = await this.documentsRepository.addDocument(
      { title: data.title },
      this.sessionService.jwt$.value,
    );

    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', data.file);

    await this.documentsRepository.editFile(formData, this.sessionService.jwt$.value);
  }

  async updateItem(item: Partial<DocumentElement>, id: DocumentElement['id']): Promise<void> {
    await this.documentsRepository.editDocument(
      { id, title: item.title },
      this.sessionService.jwt$.value,
    );
  }

  async deleteItem(id: DocumentElement['id']): Promise<void> {
    await this.documentsRepository.removeDocument(
      { id },
      this.sessionService.jwt$.value,
    );
  }

  async getItem(id: DocumentElement['id']): Promise<DocumentElement> {
    const res = await this.documentsRepository.getDocument(
      { id },
      this.sessionService.jwt$.value,
    );

    return res;
  }
}
