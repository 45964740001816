import React, { useEffect, useState } from 'react';
import { TabNavigator } from '~/common/ui';
import { AppSettingsMainElements } from './app-settings-main-elements';
import { AppSettingsContacts } from './app-settings-contacts';
import { Layout } from './layout';
import { useSettingsService } from '~/di';
import { ContactsElement, MainElement } from '~/common';

export const AppSettings: React.VFC = () => {
  const [contacts, setContacts] = useState<ContactsElement[]>([]);
  const [mainEelements, setMainElements] = useState<MainElement[]>([]);
  const { getSettings, editContacts, changePosition } = useSettingsService();

  useEffect(() => {
    getSettings().then((settings) => {
      setContacts(settings.contacts);
      setMainElements(settings.mainElements);
    });
  }, []);

  return (
    <Layout>
      <TabNavigator
        pages={[
          {
            label: 'Home page settings',
            component: <AppSettingsMainElements
              changePosition={changePosition}
              mainElements={mainEelements}
              setMainElements={setMainElements}
            />,
          },
          {
            label: 'Contact details',
            component: <AppSettingsContacts
              contacts={contacts}
              editContacts={editContacts}
              setContacts={setContacts}
            />,
          },
        ]}
      />
    </Layout>
  );
};
