import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  CrudCreateViewConfig,
  CrudDataProvider,
  CrudEditViewConfig,
  CrudIndexViewConfig,
} from '../common';
import { CrudCreate } from './crud-create';
import { CrudEdit } from './crud-edit';
import { CrudIndex } from './crud-index';

export type CrudProps = {
  crudDataProvider: CrudDataProvider;
  indexView: (config: CrudIndexViewConfig) => React.ReactNode;
  editView?: (config: CrudEditViewConfig) => React.ReactNode;
  createView?: (config: CrudCreateViewConfig) => React.ReactNode;
  title: string;
  endpoint: string;
};

export const Crud: React.VFC<CrudProps> = (props) => (
  <Routes>
    <Route
      path={'/'}
      element={(
        <CrudIndex
          crudDataProvider={props.crudDataProvider}
          indexView={props.indexView}
          title={props.title}
          endpoint={props.endpoint}
        />
      )}
    />
    {props.editView && (
      <Route
        path={'/edit/:id'}
        element={(
          <CrudEdit
            crudDataProvider={props.crudDataProvider}
            editView={props.editView}
          />
        )}
      />
    )}
    {props.createView && (
      <Route
        path={'/create'}
        element={(
          <CrudCreate
            crudDataProvider={props.crudDataProvider}
            createView={props.createView}
          />
        )}
      />
    )}
    <Route path={'*'} element={<Navigate to={'/page404'}/>}/>
  </Routes>
);
