import React from 'react';
import { TabNavigator } from '~/common/ui/components';
import { useStatsService } from '~/di';
import { usePromise } from '~/utils';
import { Layout } from './layout';
import { StatisticsTab } from './statistics-tab';
import { UsersCrud } from './users-crud';

export const UsersScreen = () => {
  const statsService = useStatsService();
  const statsPromise = usePromise(statsService.getStats);

  return (
    <Layout>
      <TabNavigator
        pages={[
          {
            label: 'Users',
            component: <UsersCrud/>,
          },
          {
            label: 'Analytics',
            component: <StatisticsTab stats={statsPromise.result}/>,
          },
        ]}
      />
    </Layout>
  );
};
