import { SessionService, useSessionService } from '~/common/session';
import { useDevicesRepositry } from '~/di';
import { DevicesRepository } from '~/repositories';

export class DeviceService {
  sessionService: SessionService;

  deviceRepository: DevicesRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.deviceRepository = useDevicesRepositry();
  }

  async deleteFile(id: string) {
    await this.deviceRepository.removeFile(
      { id },
      this.sessionService.jwt$.value,
    );
  }

  async addFile({ title, file, deviceId }: {title: string; file: File; deviceId: string;}) {
    const id = await this.deviceRepository.addFile(
      { title, deviceId },
      this.sessionService.jwt$.value,
    );

    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', file);

    const urlFile = await this.deviceRepository.editDeviceFile(
      formData,
      this.sessionService.jwt$.value,
    );

    return { id, urlFile };
  }

  async editImage({ file, deviceId }: {file: File; deviceId: string;}) {
    const formData = new FormData();
    formData.append('id', deviceId);
    formData.append('file', file);

    const url = await this.deviceRepository.editImage(
      formData,
      this.sessionService.jwt$.value,
    );

    return url;
  }

  editTitle({ title, id }: { title: string; id: string; }) {
    this.deviceRepository.editFileTitle(
      { title, id },
      this.sessionService.jwt$.value,
    );
  }
}
