import axios from 'axios';
import {
  AuthSignInRequest,
  AuthSignInResponse,
  JsonRPCResponse,
} from '~/common';
import { makeApiEndpoint } from '~/utils';

export class AuthRepository {
  /** POST:api/v1/auth/signIn */

  async postSignIn(request: AuthSignInRequest): Promise<AuthSignInResponse> {
    const response = await axios.post<JsonRPCResponse<AuthSignInResponse>>(
      makeApiEndpoint('admin/auth/sign-in'),
      {
        method: '1',
        jsonrpc: '1',
        id: '1',
        params: request,
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Sign in error');
    }

    return response.data.result;
  }
}
