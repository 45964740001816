import jwtDecode from 'jwt-decode';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';
import { SessionAuthDataProvider, SessionStorageDataProvider } from '../common';
import { useSessionAuthDataProvider, useSessionStorageDataProvider } from '../di';

export class SessionService {
  sessionAuthDataProvider: SessionAuthDataProvider;

  sessionStorageDataProvider: SessionStorageDataProvider;

  jwt$: BehaviorSubject<string | null>;

  jwtData$: BehaviorSubject<object | null>;

  constructor() {
    this.sessionStorageDataProvider = useSessionStorageDataProvider();
    this.sessionAuthDataProvider = useSessionAuthDataProvider();
    this.jwt$ = new BehaviorSubject(this.sessionStorageDataProvider.getJwt());
    this.jwtData$ = new BehaviorSubject(null);

    this.jwt$.pipe(
      map((jwt) => (jwt ? jwtDecode(jwt) : null)),
    ).subscribe(this.jwtData$);

    this.jwt$.pipe(
      distinctUntilChanged(),
    ).subscribe((jwt) => {
      if (jwt) {
        this.sessionStorageDataProvider.setJwt(jwt);
      } else {
        this.sessionStorageDataProvider.delJwt();
      }
    });
  }

  signIn(data: object): Promise<void> {
    return this.sessionAuthDataProvider.signIn(data)
      .then((jwt) => {
        this.jwt$.next(jwt);
      });
  }

  logout(): void {
    this.jwt$.next(null);
  }
}
