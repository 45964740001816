/* eslint-disable react/destructuring-assignment */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface ICrudIndexDataGridToolbarProps {
  listTitle?: string;
  addButtonText?: string;
}
export const CrudIndexDataGridToolbar: React.VFC<ICrudIndexDataGridToolbarProps> = ({
  listTitle = 'XXX',
  addButtonText = 'ADD XXX',
}) => {
  const navigate = useNavigate();

  const handleCreateClick = useCallback(() => navigate('create'), []);

  return (
    <Box margin={2}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Typography variant={'h6'}>
          {listTitle}
        </Typography>
        <Button
          variant={'contained'}
          size={'small'}
          disableElevation
          onClick={handleCreateClick}
        >
          <AddIcon/> {addButtonText}
        </Button>
      </Stack>
    </Box>
  );
};
