import { MenuItem } from '@mui/material';
import React, { FC } from 'react';

export type NotificationMenuProps = {
  notifications: Array<string>;
};

export const NotificationMenu: FC<NotificationMenuProps> = (props) => {
  const notificationsForRender = props.notifications.map((n, index) => (
    <MenuItem key={index}>
      {n}
    </MenuItem>
  ));
  return (
    <>
      {props.notifications.length ? notificationsForRender : 'You currently have no new notifications'}
    </>
  );
};
