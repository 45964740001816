import { CrudDataProvider, CrudDataProviderList } from '~/common/crud/common';
import { useDevicesRepositry } from '~/di';
import { SessionService, useSessionService } from '~/common/session';
import { DeviceElement } from '~/common';
import { DevicesRepository } from '~/repositories';

export class DevicesCrudDataProvider implements CrudDataProvider<DeviceElement, DeviceElement['id'], any> {
  sessionService: SessionService;

  deviceRepository: DevicesRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.deviceRepository = useDevicesRepositry();
  }

  async getList(limit: number, offset: number): Promise<CrudDataProviderList<DeviceElement>> {
    const res = await this.deviceRepository.getDevices(
      { limit, offset },
      this.sessionService.jwt$.value,
    );
    return {
      list: res.list,
      total: res.list.length,
    };
  }

  async createItem(data: Partial<any> & {file: any;}): Promise<void> {
    console.log('Create device', data);
    // await this.deviceRepository.addDevice(
    //   { title: data.title },
    //   this.sessionService.jwt$.value,
    // );

    // const formData = new FormData();
    // formData.append('id', id);
    // formData.append('file', data.file);

    // await this.deviceRepository.editDevice(formData, this.sessionService.jwt$.value);
  }

  async updateItem(item: Partial<DeviceElement>): Promise<void> {
    await this.deviceRepository.editDevice(
      { id: item.id, description: item.description, title: item.title },
      this.sessionService.jwt$.value,
    );
  }

  async getItem(id: string): Promise<DeviceElement> {
    const item = await this.deviceRepository.getDevice(
      { id },
      this.sessionService.jwt$.value,
    );

    return item;
  }
}
