import { Specialist } from '~/common';
import { CrudDataProvider } from '~/common/crud/common';
import { useSpecialistsRepository } from '~/di';
import { SpecialistsRepository } from '~/repositories';
import { SessionService, useSessionService } from '~/common/session';

export class SpecialistsCrudDataProvider implements CrudDataProvider<Specialist, Specialist['id']> {
  sessionService: SessionService;

  specialistRepository: SpecialistsRepository;

  constructor() {
    this.sessionService = useSessionService();
    this.specialistRepository = useSpecialistsRepository();
  }

  specialists: { [id: string]: Specialist; };

  async getList(limit: number, offset: number): Promise<{
    list: Array<Specialist>;
    total: number;
  }> {
    console.log('SpecialistsCrudDataProvider getList', limit, offset);

    const res = await this.specialistRepository.getSpecialists(
      { cursor: { limit: String(limit), skip: String(offset) } },
      this.sessionService.jwt$.value,
    );
    res.forEach((specialist) => {
      this.specialists[specialist.id] = specialist;
    });
    return {
      list: res,
      total: 100,
    };
  }

  getItem(id: Specialist['id']): Promise<Specialist> {
    return new Promise((resolve, reject) => {
      const item = this.specialists[id];

      if (item) {
        resolve(item);
      } else {
        reject(new Error('No specialist'));
      }
    });
  }

  createItem(item: Partial<Specialist>): Promise<void> {
    console.log('SpecialistsCrudDataProvider createItem >>>', item);

    return Promise.resolve(undefined);
  }

  updateItem(item: Partial<Specialist>, id: Specialist['id']): Promise<void> {
    console.log('SpecialistsCrudDataProvider updateItem >>>', id, item);

    return Promise.resolve(undefined);
  }
}
