import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';

interface ITabNavigatorProps {
  pages: Array<{
    label: string;
    component: any;
  }>;
}

export const TabNavigator = (props: ITabNavigatorProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_e: any, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label={'basic tabs example'}>
        {props.pages.map((item, index) => (
          <Tab label={item.label} id={index.toString()} key={index.toString()}/>
        ))}
      </Tabs>
      {props.pages[activeTab].component}
    </>
  );
};
