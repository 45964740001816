import { BehaviorSubject } from 'rxjs';
import { Notify } from '../common';

export class NotifyService {
  notifyList$ = new BehaviorSubject<Array<Notify>>([]);

  pushNotify(notifyData: Omit<Notify, 'id'>): void {
    const notify = {
      ...notifyData,
      id: String(Math.random()),
    };

    this.notifyList$.next([
      ...this.notifyList$.value,
      notify,
    ]);

    setTimeout(() => {
      this.removeNotify(notify.id);
    }, 5000);
  }

  removeNotify(id: Notify['id']): void {
    this.notifyList$.next([
      ...this.notifyList$.value.filter((notify) => notify.id !== id),
    ]);
  }
}
