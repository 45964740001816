import React, { useCallback } from 'react';
import { DeviceElement } from '~/common';
import {
  Crud,
  CrudEditBaseView,
  CrudEditViewConfig,
  CrudIndexViewConfig,
} from '~/common/crud';
import { useDevicesCrudDataProvider } from '~/di';
import { DeviceCrudIndexView } from './device-crud-index-view';
import { DeviceEditView } from './device-edit-view';
import { Layout } from './layout';

export const DevicesCrud: React.VFC = () => {
  const devicesCrudDataProvider = useDevicesCrudDataProvider();

  const indexView = useCallback((config: CrudIndexViewConfig) => (
    <DeviceCrudIndexView config={config}/>
  ), []);

  const editView = useCallback((config: CrudEditViewConfig<DeviceElement>) => (
    <CrudEditBaseView
      config={config}
      form={DeviceEditView}
    />
  ), []);

  return (
    <Layout>
      <Crud
        crudDataProvider={devicesCrudDataProvider}
        indexView={indexView}
        editView={editView}
        title={'Devices'}
        endpoint={'devices'}
      />
    </Layout>
  );
};
