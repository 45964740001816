/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { CrudDataProviderList, CrudIndexViewConfig } from '../common';
import { useBehaviorSubject, usePromise } from '~/utils';
import { useUrlParamsService } from '~/di';

const defaultRowsPerPageOptions = [10, 20, 50];
const defaultResult: CrudDataProviderList = { list: [], total: 0 };

type CrudIndexDataGridViewProps = {
  config: CrudIndexViewConfig;
  columns: Array<GridColDef>;
  rowsPerPageOptions?: Array<number>;
  toolbar?: React.VFC;
  ListIcon?: React.VFC;
  onIconClick?: (params: GridRowParams, updater?: () => void) => void;
};

export const CrudIndexDataGridView: React.VFC<CrudIndexDataGridViewProps> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { rowsPerPageOptions = defaultRowsPerPageOptions, ListIcon = EditIcon } = props;

  const navigation = useNavigate();
  const urlParamsService = useUrlParamsService();
  const urlString = useBehaviorSubject(urlParamsService.urlString$);
  const urlParams = urlParamsService.readUrlParams();
  const [listUpdateTarget, updateListTarget] = useState(false);
  const [page, setPage] = useState(
    Math.floor(+urlParams?.offset / +urlParams?.limit) || 0,
  );
  const [pageSize, setPageSize] = useState(+urlParams?.limit || rowsPerPageOptions[0]);

  const data = usePromise(
    () => (urlString ? props.config.dataProvider.getList(
      pageSize,
      page * pageSize,
      urlParams?.search,
    ) : Promise.resolve(defaultResult)),
    [urlString, listUpdateTarget],
  );

  useEffect(() => () => urlParamsService.clearUrl(), []);

  useEffect(() => {
    urlParamsService.setUrlParams({
      limit: pageSize,
      offset: page * pageSize,
      search: urlParams?.search,
    });
  }, [page, pageSize]);

  useEffect(() => {
    if (+urlParams?.limit && !(+urlParams?.offset)) {
      setPage(Math.floor(+urlParams?.offset / +urlParams?.limit));
      setPageSize(
        rowsPerPageOptions.indexOf(+urlParams?.limit) < 0
          ? rowsPerPageOptions[0] : +urlParams?.limit,
      );
    }
  }, [urlString]);

  const { result = defaultResult } = data;

  const handleClickDefault = (params: GridRowParams) => {
    navigation(`edit/${params.id}`);
  };

  // eslint-disable-next-line react/destructuring-assignment
  const { onIconClick = handleClickDefault } = props;

  const getActions = useCallback((params: GridRowParams) => [
    <GridActionsCellItem
      icon={<ListIcon/>}
      onClick={() => onIconClick(params, () => updateListTarget(!listUpdateTarget))}
      label={'Edit'}
      showInMenu={false}
    />,
  ], [navigation]);

  const columns = useMemo(
    () => [
      ...props.columns.map((column) => ({ ...column, sortable: false })),
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        getActions,
      },
    ],
    [props.columns],
  );

  console.log(rowsPerPageOptions);

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      disableDensitySelector
      disableColumnSelector
      disableSelectionOnClick
      paginationMode={'server'}
      sortingMode={'server'}
      columns={columns}
      loading={data.loading}
      rowsPerPageOptions={rowsPerPageOptions}
      rows={result.list}
      rowCount={result.total}
      page={page}
      pageSize={pageSize}
      onPageChange={(a) => !data.loading && setPage(a)}
      onPageSizeChange={(a) => !data.loading && setPageSize(a)}
      components={{
        Toolbar: props.toolbar,
      }}
    />
  );
};
