import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { createUseStyles } from 'react-jss';
import { ChartConfiguration } from 'chart.js';
import { StatsResponse } from '~/common';
import { ChartTitle } from './chart-title';

 interface IStatisticsTabProps {
   stats: StatsResponse;
 }

const useStyles = createUseStyles({
  container: {
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  pieContainer: {
    width: 280,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  chartContainer: {
    width: 380,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export const StatisticsTab: React.FC<IStatisticsTabProps> = (props) => {
  const styles = useStyles();

  const genderTotal = props.stats.stat.femaleCount + props.stats.stat.maleCount;
  const pieData = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        data: [props.stats.stat.maleCount, props.stats.stat.femaleCount],
        backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const getBarData = (
    stats: Array<{count: number; [propName: string]: number;}>,
    needParams: string,
  ) => {
    const filteredStat = stats.filter((item) => !!item[needParams]);

    return ({
      labels: filteredStat.map((item) => item[needParams]),
      datasets: [
        {
          data: filteredStat.map((item) => item.count),
          backgroundColor: 'rgba(255, 159, 64, 0.5)',
        },
      ],
    });
  };

  const pieOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxHeight: 10,
          boxWidth: 10,
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.pieContainer}>
        <ChartTitle
          value={genderTotal}
          category={'Gender'}
          title={'Total'}
          style={{ marginBottom: 0 }}
        />
        <Pie
          data={pieData}
          options={pieOptions}
        />
      </div>
      <div className={styles.chartContainer}>
        <ChartTitle
          value={props.stats.stat.avgWeight}
          title={'Average'}
          category={'Weight'}
        />
        <Bar
          data={getBarData(props.stats.weightStat, 'weight')}
          options={{ plugins: { legend: { display: false } } }}
        />
      </div>
      <div className={styles.chartContainer}>
        <ChartTitle
          value={props.stats.stat.avgGrowth}
          category={'Height'}
          title={'Average'}
        />
        <Bar
          data={getBarData(props.stats.growthStat, 'growth')}
          options={{ plugins: { legend: { display: false } } }}
        />
      </div>
      <div className={styles.chartContainer}>
        <ChartTitle
          value={props.stats.stat.avgAgeYears}
          category={'Age'}
          title={'Average'}
        />
        <Bar
          data={getBarData(props.stats.ageStat, 'age')}
          options={{ plugins: { legend: { display: false } } }}
        />
      </div>
    </div>
  );
};
