/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { reorder } from '~/utils/reorder';
import { MainElement } from '~/common/main';

interface IDraggableBlock {
  item: MainElement;
  index: number;
  list: Array<{
    id: string;
    title: string;
  }>;
  setList: (newList: {
    id: string;
    title: string;
  }[]) => void;
  changePosition: (id: string, orderNumber: number) => void;
}

const useStyles = createUseStyles({
  element: {
    border: '2px solid #5ABEB6',
    maxWidth: 250,
    padding: '0 10px',
    marginBottom: '10px',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 35,
    height: 60,
  },
  arrow: {
    'cursor': 'pointer',
    'width': 35,
    '&:hover': {
      opacity: 0.5,
    },
  },
});

export const DraggableBlock = (props: IDraggableBlock) => {
  const styles = useStyles();

  const handleUpClick = () => {
    props.setList(reorder(
      props.list,
      props.index,
      props.index - 1 < 0 ? 0 : props.index - 1,
    ));
    if (props.index !== 0) {
      props.changePosition(props.item.id, props.index);
    }
  };

  const handleDownClick = () => {
    props.setList(reorder(
      props.list,
      props.index,
      props.index + 1,
    ));
    if (props.index + 2 <= 3) {
      props.changePosition(props.item.id, props.index + 2);
    }
  };

  return (
    <Draggable
      draggableId={props.item.id}
      index={props.index}
      key={`draggable-${props.item}`}
    >
      {(dragProvided) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
          className={styles.element}
        >
          {props.item.title}
          <div className={styles.arrowContainer}>
            <ArrowUpwardIcon
              fontSize={'small'}
              className={styles.arrow}
              onClick={handleUpClick}
            />
            <ArrowDownwardIcon
              fontSize={'small'}
              className={styles.arrow}
              onClick={handleDownClick}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};
