import React from 'react';
import { Box } from '@mui/material';
import { CrudDataProvider, CrudIndexViewConfig } from '../common';

export type CrudIndexProps = {
  crudDataProvider: CrudDataProvider;
  indexView: (config: CrudIndexViewConfig) => React.ReactNode;
  title: string;
  endpoint: string;
};

export const CrudIndex: React.VFC<CrudIndexProps> = (props) => (
  <Box sx={{ marginY: 2 }}>
    {props.indexView({
      dataProvider: props.crudDataProvider,
    })}
  </Box>
);
