import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useBehaviorSubject } from '~/utils';
import { useSessionService } from '../di';
import { SessionLogin } from './session-login';

export const Session: React.FC = (props) => {
  const sessionService = useSessionService();
  const jwt = useBehaviorSubject(sessionService.jwt$);

  if (jwt) {
    return (
      <>{props.children}</>
    );
  }

  return (
    <Routes>
      <Route path={'/'} element={<SessionLogin/>}/>
      <Route path={'*'} element={<Navigate replace to={'/'}/>}/>
    </Routes>
  );
};
