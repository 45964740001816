import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DocumentsCrud } from './documents-crud';
import { AppSettings } from './app-settings';
import { UsersScreen } from './users-screen';
import { DevicesCrud } from './devices-crud';

export const Router: React.FC = () => (
  <Routes>
    <Route path={'/app-settings/*'} element={<AppSettings/>}/>
    <Route path={'/devices/*'} element={<DevicesCrud/>}/>
    <Route path={'/users/*'} element={<UsersScreen/>}/>
    <Route path={'/documents/*'} element={<DocumentsCrud/>}/>
    <Route path={'*'} element={<Navigate replace to={'/app-settings'}/>}/>
  </Routes>
);
