import axios from 'axios';
import {
  JsonRPCResponse,
  AddFileRequest,
  DevicesRequest,
  DevicesResponse,
  EditDeviceRequest,
  EditFileTitleRequest,
  OneDeviceRequest,
  OneDeviceResponse,
  RemoveFileRequest,
  EditImageResponse,
  JsonRPCShortResponse,
} from '~/common';
import { makeApiEndpoint } from '~/utils';

export class DevicesRepository {
  async getDevices(params: DevicesRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<DevicesResponse>>(
      makeApiEndpoint('v1/settings/devices'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get devices error');
    }

    return response.data.result;
  }

  async getDevice(params: OneDeviceRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<OneDeviceResponse>>(
      makeApiEndpoint('v1/settings/devices/one'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Get device error');
    }

    return response.data.result;
  }

  async addFile(params: AddFileRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/device-files/add'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Add device error');
    }

    return response.data.result;
  }

  async editFileTitle(params: EditFileTitleRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/device-files/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Edit deviceTitle error');
    }

    return response.data.result;
  }

  async editDeviceFile(params: FormData, jwt: string) {
    const response = await axios.post<JsonRPCShortResponse<{ url: string; }>>(
      makeApiEndpoint('admin/settings/device-files/file/edit'),
      params,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.status) {
      throw new Error(response.data.message || 'Edit device error');
    }

    return response.data.data.url;
  }

  async editDevice(params: EditDeviceRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/devices/edit'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Edit device error');
    }

    return response.data.result;
  }

  async removeFile(params: RemoveFileRequest, jwt: string) {
    const response = await axios.post<JsonRPCResponse<string>>(
      makeApiEndpoint('admin/settings/device-files/remove'),
      {
        method: '1',
        id: '1',
        jsonrpc: '1',
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (response.data.error) {
      throw new Error(response.data.error.message || 'Remove device error');
    }

    return response.data.result;
  }

  async editImage(params: FormData, jwt: string) {
    const response = await axios.post<JsonRPCShortResponse<EditImageResponse>>(
      makeApiEndpoint('admin/settings/devices/image/edit'),
      params,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );

    if (!response.data.status) {
      throw new Error(response.data.message || 'Image edit error');
    }

    return response.data.data.url;
  }
}
